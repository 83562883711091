/* body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
} */

body {
    overflow-x: hidden;
}

@media screen and (max-width:679px) {
    * {
        overflow-x: hidden;
    }
}

@import url('https://fonts.googleapis.com/css2?family=Karla');
@import url('https://fonts.googleapis.com/css2?family=Montserrat');
h1,
h2,
h3,
h4,
h5,
h6,
ul,
li,
li,
button {
    font-family: 'Montserrat', sans-serif;
}

p,
div,
span {
    font-family: 'Karla', sans-serif;
}

a:visited,
a:link {
    text-decoration: none;
}