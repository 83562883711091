* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.grey-markdown {
    color: #5b6278
}

.markdown {
    color: #828282;
    text-transform: uppercase;
    text-align: center;
    letter-spacing: 1.25px;
    padding: 2rem 0;
    font-weight: normal;
}

.markdown1 {
    color: #424242;
    text-transform: uppercase;
    text-align: left;
    letter-spacing: 1.25px;
    font-weight: normal;
}

.main-header {
    font-size: 1.9rem;
    letter-spacing: normal;
    font-weight: 700;
    padding-bottom: 2rem;
    line-height: normal;
}

.restrict {
    border: 3px solid yellow;
    width: 70%;
    align-self: flex-start;
}

.Btext-paragraph {
    color: #E0E0E0;
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 2.1rem;
    text-align: center;
    letter-spacing: .25px;
}

.container {
    max-width: 70%;
    width: max-content;
}

.Wtext-paragraph {
    color: #828282;
    font-size: 1.25rem;
    ;
    font-weight: 400;
}

.blackText {
    color: #424242;
}

.whiteText {
    color: #E0E0E0;
}

.subLink {
    color: #E0E0E0;
    font-size: 1.25rem;
    text-align: center;
    font-weight: 400;
    text-decoration: none;
}

.space-around {
    padding: .3em 1.3em;
}

.summit-header {
    color: #424242;
    font-weight: 700;
    font-size: 1.25em;
}

@media screen and (max-width:920px) {
    .container {
        max-width: 100%;
        width: max-content;
    }
    .summit-header {
        font-size: 1rem;
        margin: .3em 0;
    }
}