    .header {
        width: 100%;
        height: 5rem;
        background-color: #131313;
        display: flex;
        justify-content: center;
        position: sticky;
        top: 0;
        z-index: 3;
        font-family: 'Montserrat', sans-serif;
    }
    
    .header nav {
        width: 80%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    .oshLogoCon {
        width: fit-content;
        display: grid;
        align-items: center;
        /* margin-left: 5rem; */
    }
    
    .oshLogo {
        width: 3rem;
        object-fit: contain;
    }
    
    nav ul {
        display: flex;
        align-items: center;
    }
    
    .navLinks {
        list-style: none;
        display: inline;
    }
    
    nav li {
        display: block;
        /* margin-left: 1.9rem; */
        padding: 1rem 1.5rem;
        text-transform: uppercase;
        text-decoration: none;
        outline: none;
    }
    
    nav a {
        font-size: 14px;
        text-decoration: none;
        color: #E0E0E0;
        padding: 1rem 0;
        outline: none;
    }
    
    nav a:hover,
    nav a:active,
    nav a.active {
        color: #fff;
        border-bottom: 2px solid #fff;
    }
    
    .hamburgerCon {
        display: none;
        color: white;
    }
    
    .StyledBox-sc-13pk1d4-0 {
        padding: 0;
    }
    
    @media screen and (min-width:1024px) {
        ul {
            display: flex
        }
    }
    
    @media screen and (max-width:920px) {
        nav li {
            padding: 1.5rem 1.5rem;
        }
        nav {
            flex-direction: column;
        }
        nav ul {
            flex-direction: column;
            position: absolute;
            top: 4.9rem;
            width: 100%;
            left: 0;
            background-color: rgba(0, 0, 0, .7);
            z-index: 999;
        }
        .d-none {
            display: none;
        }
        .hamburgerCon {
            display: grid;
            align-items: center;
        }
    }