.mainHeader {
    text-align: center;
}

.subLink {
    padding: 2rem 0;
    color: #e0e0e0;
    text-decoration: none;
    display: flex;
    justify-content: center;
}